<template>
<div class="createapp">
   <div v-if="hasAdminRights">
      <div class="p-d-flex p-grid">
        <div class="card p-col-12" id="addApp">
        <div class="p-d-flex p-jc-between">
            <div class="p-mr-2">
              <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
            </div>
              <div class="p-col-1-cust">
              <a :href="`/app/${appId}/details`" class="p-mr-2" style="text-decoration:none">
                <Button label="View Application" icon="fas fa-eye" class="p-button-secondary iconmargin"/>
              </a>
                <a href="/createapp" style="text-decoration:none">
                <Button label="Create Application" icon="fas fa-edit" class="p-button-secondary iconmargin"/>
              </a>     
            </div>
        </div>
          <div v-if="isLoading">
          <SkeletonAppForm/>
          </div>
          <div class="editapp" v-else>
            <div v-if="appDetails">
            <div class="p-fluid p-grid" >
              <div class="p-field p-col-12 p-md-8 p-pt-4 p-pl-4 p-pr-4">
                <span class="p-float-label">
                  <InputText id="inputtext" type="text" v-model="appDetails.title" />
                  <label for="inputtext" class="floatlabel">App Title</label>
                </span>
                  <label class="error-message">{{ validation.firstError("appDetails.title") }}</label>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-field p-col-12 p-md-4 p-m-0">
                <span class="p-float-label">
                <Dropdown v-model="appDetails.type" :options="appTypes" optionLabel="name" 
                :showClear="true" :filter="true" filterPlaceholder="Search For Type">
                <template #option="slotProps">
                      <div><span><i :class="slotProps.option.icon" class="p-mr-1"></i></span>{{slotProps.option.name}}</div>
                  </template>
                </Dropdown>
                  <label for="inputtext" class="floatlabel">App Type</label>
                </span>
                <label class="error-message">{{ validation.firstError("appDetails.type") }}</label >
              </div>
              <div class="p-field p-col-12 p-md-4 p-m-0">
                <span class="p-float-label">
                <Dropdown v-model="appDetails.stage" :options="appStages" optionLabel="name"
                  :showClear="true" :filter="true" filterPlaceholder="Search For Stage">
                  <template #option="slotProps">
                      <div><span><i :class="slotProps.option.icon" class="p-mr-1"></i></span>{{slotProps.option.name}}</div>
                  </template>      
                </Dropdown>
                  <label for="inputtext" class="floatlabel">App Stage</label>
                </span>
                <label class="error-message">{{ validation.firstError("appDetails.stage") }}</label >
              </div>
              <div class="p-field p-col-12 p-md-4 p-m-0">
                <span class="p-float-label">
                <AutoComplete v-model="appDetails.business_owner" :suggestions="businessOwners" @complete="searchOwnerWithEmail($event)" field="mail" >
                  <template #item="slotProps">
                              <div>
                              <div><span><i class="fas fa-user"></i></span> {{ slotProps.item.displayName }}</div>
                                <div><span><i class="fas fa-envelope-open-text"></i></span> {{ slotProps.item.mail }}</div>
                              </div>
                            </template>
                </AutoComplete>
                  <label for="inputtext" class="floatlabel">App Business Owner</label>
                </span>
                <label class="error-message">{{ validation.firstError("appDetails.business_owner") }}</label >
                <small id="username1-help">search with user email</small>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-field p-col-12 p-md-4 p-m-0">
                <span class="p-float-label">
                <AutoComplete v-model="appDetails.app_owner1" :suggestions="appOwners" @complete="searchOwnerWithName($event)" field="mail">
                  <template #item="slotProps">
                              <div>
                              <div><span><i class="fas fa-user"></i></span> {{ slotProps.item.displayName }}</div>
                                <div><span><i class="fas fa-envelope-open-text"></i></span> {{ slotProps.item.mail }}</div>
                              </div>
                  </template>
                </AutoComplete>
                  <label for="inputtext" class="floatlabel">App Owner 1</label>
                </span>
                <label class="error-message" v-if="validation.firstError('appDetails.app_owner1')">{{ validation.firstError("appDetails.app_owner1") }}</label >
                <label class="error-message" v-else-if="isValidAppOwner1">select user from the list </label >
                <small id="username1-help">search with user name</small>
              </div>
              <div class="p-field p-col-12 p-md-4 p-m-0">
                <span class="p-float-label">
                <AutoComplete v-model="appDetails.app_owner2" :suggestions="appOwners" @complete="searchOwnerWithName($event)" field="mail">
                  <template #item="slotProps">
                              <div>
                              <div><span><i class="fas fa-user"></i></span> {{ slotProps.item.displayName }}</div>
                                <div><span><i class="fas fa-envelope-open-text"></i></span> {{ slotProps.item.mail }}</div>
                              </div>
                            </template>
                </AutoComplete>
                  <label for="inputtext" class="floatlabel">App Owner 2</label>
                </span>
                  <label class="error-message" v-if="validation.firstError('appDetails.app_owner2')">{{ validation.firstError("appDetails.app_owner2") }}</label >
                  <label class="error-message" v-else-if="isValidAppOwner2">select user from the list </label >
                <small id="username1-help">search with user name</small>
              </div>
              <div class="p-field p-col-12 p-md-4 p-m-0">
                <span class="p-float-label">
                <AutoComplete v-model="appDetails.app_owner3" :suggestions="appOwners" @complete="searchOwnerWithName($event)" field="mail">
                  <template #item="slotProps">
                              <div>
                              <div><span><i class="fas fa-user"></i></span> {{ slotProps.item.displayName }}</div>
                                <div><span><i class="fas fa-envelope-open-text"></i></span> {{ slotProps.item.mail }}</div>
                              </div>
                            </template>
                </AutoComplete>
                  <label for="inputtext" class="floatlabel">App Owner 3</label>
                </span>
                  <label class="error-message" v-if="validation.firstError('appDetails.app_owner3')">{{ validation.firstError("appDetails.app_owner3") }}</label >
                  <label class="error-message" v-else-if="isValidAppOwner3">select user from the list </label >
                  <small id="username1-help">search with user name</small>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-field p-col-12 p-md-8 p-p-4">
                <span class="p-float-label">
                <MultiSelect  v-model="appDetails.related_groups" :options="appGroups" optionLabel="name" display="chip" :filter="true"  :showClear="true" />
                  <label for="inputtext" class="floatlabel">Related Groups
                    <span class="p-col-1  p-p-0" v-if="appDetails.related_groups">
                        <chip class="p-col-chip" v-if="appDetails.related_groups.length > 0 ">
                        {{ToArryLength(appDetails.related_groups)}}
                        </chip>
                    </span>
                  </label>
                </span>
                <label class="error-message">{{ validation.firstError("appDetails.related_groups") }}</label >
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-field p-col-12 p-md-8 p-p-4">
                <span class="p-float-label">
                  <Chips separator="," v-model="appDetails.tags" />
                  <label for="inputtext" class="floatlabel">Tags
                  <span class="p-col-1  p-p-0" v-if="appDetails.tags">
                        <chip class="p-col-chip" v-if="appDetails.tags.length > 0 ">
                        {{ToArryLength(appDetails.tags)}}
                        </chip>
                  </span>  
                  </label>
                </span>
                <small id="username1-help">comma separator for multiple</small>
              </div>
            </div>
            <div class="card">
                <div class="p-d-flex">
                  <div class="error-message p-mr-4"  v-if="validation.firstError('appDetails.description')">
                    Description Is {{ validation.firstError("appDetails.description") }}
                  </div>
                  <div class="error-message p-mr-4" v-if="validation.firstError('appDetails.links')">
                    Links Are {{ validation.firstError("appDetails.links") }}
                  </div>
                  <div  class="error-message" v-if="validation.firstError('appDetails.environments')" >
                    Environments Are   {{ validation.firstError("appDetails.environments") }}
                  </div>
                </div>
              <TabView ref="tabview2" :activeIndex="active">
                <TabPanel header="Description">
                  <Editor v-model="appDetails.description" editorStyle="height: 177px" />
                </TabPanel>
                <TabPanel header="Project Links">
                  <AppLinks 
                  :addLink="handleAddAppLink"
                  :removeLink="handleRemooveAppLink"
                  :appMasterLinks="appLinks"
                  :updateLink="handleUpdateAppLink"
                  :appLinks="appDetails.links"/>
                </TabPanel>
                <TabPanel header="Project Environments">
                  <AppEnvironments
                  :addEnvironment="handleAddAppEnvironment"
                  :removeEnvironment="handleRemoveAppEnvironment"
                  :appEnvironments="appDetails.environments"
                  :UpdateEnvironment="handleUpdateAppEnvironment"
                  :appMasterLinks="appLinks"
                  :addEnvironmentLinks="handleAddAppEnvironmentLinks"
                  :removeEnvironmentLink="handleRemoveAppEnvironmentLink"
                  />
                </TabPanel>
                <TabPanel header="Project Versions">
                  <AppVersions
                  :addVersion="handleAddAppVersion"
                  :removeVersion="handleRemoveAppVersion"
                  :appVersions="appDetails.versions"
                  :updateVersion="handleUpdateAppVersion"
                  />
                </TabPanel>
                <TabPanel header="Project Discussion">
                  <AppDiscussion
                  :addComment="handleAddAppComment"
                  :removeComment="handleRemoveAppComment"
                  :updateComment="handleUpdateAppComment"
                  :appComments="appDetails.comments"/>
                </TabPanel>
              </TabView>
            </div> 
        </div>
          </div>
        </div>
        <div class="layout-bottombar p-d-flex p-jc-center">
              <div class="p-py-2">
                <Button label="Update Application" class="p-button-secondary" 
                  @click="UpdateApplication()"
                style="margin-left:-15%" icon="fas fa-arrow-up"/>  
              </div>        
          </div>
      </div>
   </div>
     <div v-else>
    <ForbiddenView/>
   </div>
</div>
</template>
<script>
import AppLinks from "@/components/Apps/AppLinks";
import AppEnvironments from "@/components/Apps/AppEnvironment";
import AppVersions from "@/components/Apps/AppVersions";
import AppDiscussion from "@/components/Apps/AppDiscussion";
import SkeletonAppForm from "@/components/Shared/SkeletonAppForm.vue";
import ForbiddenView from "../Errors/403.vue"
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  name: "appDetailsComponent",
  created() {
    this.appId = this.$route.params.appid;
    this.hasAdminRights=localStorage.getItem("isUserAdmin")=='true'?true:false
    this.loadMasetData();
    this.getAppById();
  },
  components: { AppLinks,AppEnvironments,AppVersions,AppDiscussion,SkeletonAppForm,ForbiddenView },
  methods: {
    async loadMasetData(){
      try {
          this.appTypes = await this.$store.dispatch("types/getAppTypes");
          this.appGroups= await this.$store.dispatch("group/getGroups");
          this.appStages = await this.$store.dispatch("stage/getStages");
          this.appLinks = await this.$store.dispatch("link/getLinks");
          this.isLoading=false;
       }
       catch (error) {
        console.warn(error);
      }
    },
    async getAppById() {
      try {
        let _appId = this.appId;
        let result = await this.$store.dispatch("app/getAppById", _appId);
        let appDetails = result.data;
        let appType = appDetails.app_type;
        let appStage= appDetails.stage;
        let relatedGroups=[];
        this.appGroups.forEach(appgrp => {
          appDetails.related_groups.forEach(reappgrp => {
            if(appgrp.name.toLowerCase() ==reappgrp.toLowerCase()){
                relatedGroups.push(appgrp)
            }
          });
        });
        appDetails.type=this.appTypes.find(type => type.name == appType);
        appDetails.stage= this.appStages.find(stage => stage.name==appStage );
        appDetails.type=appDetails.type?appDetails.type:appType;
        appDetails.stage=appDetails.stage?appDetails.stage:appStage;
        if(appDetails.app_owner1){
          appDetails.app_owner1 = {
            displayName: appDetails.app_owner1.displayName,
            mail: appDetails.app_owner1.email?appDetails.app_owner1.email:appDetails.app_owner1.displayName,
            id: appDetails.app_owner1.id,
          }; 
        }
        if(appDetails.app_owner2){
          appDetails.app_owner2 = {
            displayName: appDetails.app_owner2.displayName,
            mail: appDetails.app_owner2.email?appDetails.app_owner2.email:appDetails.app_owner2.displayName,
            id: appDetails.app_owner2.id,
          };
        }
        if(appDetails.app_owner3){
        appDetails.app_owner3 = {
          displayName: appDetails.app_owner3.displayName,
          mail: appDetails.app_owner3.email?appDetails.app_owner3.email:appDetails.app_owner3.displayName,
          id: appDetails.app_owner3.id,
        };
        }
        appDetails.related_groups=relatedGroups;
        this.appDetails= appDetails;
      } catch (error) {
        console.warn(error);
      }
    },
    async searchAppsByName(app){
        try {
        let _appname= app.query;
        let result = await this.$store.dispatch("app/getAppsByName", _appname);
        this.filteredApps= result.data;
      } catch (error) {
        console.warn(error);
      }    
    },
    async getUsersWithEmail(filterquery) {
      try {
        let result = await this.$store.dispatch("user/getUsers",filterquery);
        this.businessOwners = result.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async getUsersWithname(filterquery) {
      try {
        let result = await this.$store.dispatch("user/getUsersWithname",filterquery);
        this.appOwners = result.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async searchOwnerWithEmail(event){
        let filterquery= event.query;
        await this.getUsersWithEmail(filterquery);
    },
    async searchOwnerWithName(event){
        let filterquery= event.query;
        await this.getUsersWithname(filterquery);
    },
    handleAddAppLink(_link){
      this.appDetails.links=this.appDetails.links||[];
      let link = {
        title: _link.title["name"],
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
        url: _link.url,
      }
      this.appDetails.links.push(link)
    },
    handleRemooveAppLink(_index){
      this.appDetails.comments.splice(_index, 1);
    },
    handleUpdateAppLink(_link,_index){
    let link = {
        title: _link.title["name"],
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
        url: _link.url,
      };
      this.appDetails["links"][_index]=link;
    },
    handleAddAppComment(_comment) {
      let comment = {
        comment: _comment,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
      }
      this.appDetails.comments=this.appDetails.comments ||[];
      this.appDetails.comments.push(comment);
    },
    handleRemoveAppComment(_index) {
      this.appDetails.comments.splice(_index, 1);
    },
    handleUpdateAppComment(_comment,_index){
    this.appDetails["comments"][_index].comment=_comment;
    },
    handleAddAppEnvironment(_env){
      let environment = {
        name: _env.name,
        title: _env.title,
        description: _env.description,
        url: _env.url,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
        links: [],
      };
      this.appDetails.environments=this.appDetails.environments ||[];
      this.appDetails.environments.push(environment);
    },
    handleRemoveAppEnvironment(_index){
    this.appDetails.environments.splice(_index, 1);
    },
    handleRemoveAppEnvironmentLink(_index,envId){
      this.appDetails.environments.forEach((element) => {
        if (element.id == envId) {
          element.links.splice(_index, 1);
        }
      });
    },
    handleUpdateAppEnvironment(_env,_index){
      let environment = {
        name: _env.name,
        title: _env.title,
        description: _env.description,
        url: _env.url,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
        links: _env.links,
      };
    this.appDetails["environments"][_index]=environment;
    },
    handleAddAppEnvironmentLinks(environment) {
      let _environment = environment;
     // let _links = _environment.links;
      // this.appDetails["environments"].forEach((element) => {
      //   if (element.title == _environment.title) {
      //     element.links = element.links || [];
      //     _links.forEach((link) => {
      //       element["links"].push(link);
      //     });
      //   }
      // });
      console.log(_environment);
    },
    handleAddAppVersion(_version){
      let version = {
        title: _version.title,
        start_date: _version.start_date,
        go_live_date: _version.go_live_date,
        description: _version.description,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
      }
      this.appDetails.versions=this.appDetails.versions ||[];
      this.appDetails.versions.push(version);
    },
    handleRemoveAppVersion(_index){
      this.appDetails.versions.splice(_index, 1);
    },
    handleUpdateAppVersion(_version,_index){
      let version = {
        title: _version.title,
        start_date: _version.start_date,
        go_live_date: _version.go_live_date,
        description: _version.description,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
      };
      this.appDetails.versions[_index]=version;
    },
    async UpdateApplication(){
      let isInvalidValidAppOwners= this.isValidAppOwner1 || this.isValidAppOwner2 || this.isValidAppOwner3
          await this.$validate().then((success) => {
            if (success && !isInvalidValidAppOwners) {
              let input = this.appDetails;
              let appowner1 = {
                displayName: input.app_owner1.displayName,
                email: input.app_owner1.mail,
                id: input.app_owner1.id,
              };
              let appowner2 = {
                displayName: input.app_owner2.displayName,
                email: input.app_owner2.mail,
                id: input.app_owner2.id,
              };
              let appowner3 = {
                displayName: input.app_owner3.displayName,
                email: input.app_owner3.mail,
                id: input.app_owner3.id,
              };
              let businessOwner = {
                displayName: input.business_owner.displayName?input.business_owner.displayName:"",
                email: input.business_owner.mail?input.business_owner.mail:input.business_owner,
                id: input.business_owner.id?input.business_owner.id:"",
              };
              let relatedGroups = input.related_groups.map((rgp) => rgp.name);
              let versions= input?.versions;
                if (versions?.length){
                  versions.forEach(element => {
                    element.start_date=typeof(element.start_date)!='string'?element.start_date.toLocaleDateString():element.start_date;
                    element.go_live_date=typeof(element.go_live_date)!='string'?element.go_live_date.toLocaleDateString():element.go_live_date;
                  });
              }
              let application = {
                id:this.appId,
                title: input.title,
                description: input.description,
                links: input.links,
                app_type: input.type["name"]?input.type["name"]:input.type,
                environments: input.environments,
                related_groups: relatedGroups,
                stage: input.stage["name"]?input.stage["name"]:input.stage,
                business_owner: businessOwner.email,
                business_owner_name : businessOwner.displayName,
                tags: input.tags,
                comments: input.comments,
                versions: versions == null ? [] : versions,
                app_owner1: appowner1,
                app_owner2: appowner2,
                app_owner3: appowner3,
              };
              try {
                this.$store.dispatch("app/updateApp", application);
                sessionStorage.removeItem("selectedstages");
                sessionStorage.removeItem("selectedname");
                sessionStorage.removeItem("selectedgroups");
                this.$router.push({ name: "apps" });
              } catch (error) {
                console.warn(error);
              }          
           }
          });
    }
  },
  props: {},
  watch: {
    "appDetails.app_owner1": function(newVal) {
      let value= newVal == null ? "" : newVal.mail?newVal.mail:newVal;
      if(value.indexOf('@areteir') < 0){
        this.isValidAppOwner1=true;
      }
      else{
        this.isValidAppOwner1=false;
      }
    },
    "appDetails.app_owner2": function(newVal) {
      let value= newVal == null ? "" : newVal.mail?newVal.mail:newVal;
      if(value.indexOf('@areteir') < 0){
        this.isValidAppOwner2=true;
      }
      else{
        this.isValidAppOwner2=false;
      }
    },
    "appDetails.app_owner3": function(newVal) {
      let value=newVal == null ? "" : newVal.mail?newVal.mail:newVal;
      if(value.indexOf('@areteir') < 0){
        this.isValidAppOwner3=true;
      }
      else{
        this.isValidAppOwner3=false;
      }
    },
  },
  validators: {
    "appDetails.title": function (value) {
       return Validator.value(value).required().regex('^[A-Za-z 0-9.]*$', 'Must only contain alphanumeric with(.) characters.');
    },
    "appDetails.type": function (value) {
      return Validator.value(value).required();
    },
    "appDetails.stage": function (value) {
      return Validator.value(value).required();
    },
    "appDetails.business_owner": (value) => {
      return Validator.value(value == null ? "" : value.mail ? value.mail : value).required().email();
    },
    "appDetails.app_owner1": function (value) {
      return  value == null ? Validator.value(value).required() : value.mail ? Validator.value(value.mail).required().email():Validator.value(value).required().regex('^[A-Za-z ]*$', 'Must only contain alphabetic characters.')
    },
    "appDetails.app_owner2": function (value) {
        return value == null ? Validator.value(value).required() : value.mail? Validator.value(value.mail).required().email().custom(() =>
      {
      if (!Validator.isEmpty(value.mail)) {
        let owner1= this.appDetails.app_owner1;
          if (value.mail == owner1.mail) {
            return 'app owner email should be unique';
          }
        }
      }):Validator.value(value).required().regex('^[A-Za-z ]*$', 'Must only contain alphabetic characters.')
    },
    "appDetails.app_owner3": function (value) {
      return value == null ? Validator.value(value).required() : value.mail? Validator.value(value.mail).required().email().custom(() =>
      {
      if (!Validator.isEmpty(value.mail)) {
        let owner1= this.appDetails.app_owner1;
        let owner2= this.appDetails.app_owner2;
          if (value.mail == owner1.mail || value.mail ==owner2.mail) {
          return 'app owner email should be unique';
          }
        }
      }):Validator.value(value).required().regex('^[A-Za-z ]*$', 'Must only contain alphabetic characters.')
    },
    "appDetails.related_groups": function (value) {
      return Validator.value(value).required();
    },
    "appDetails.description": (value) => {
      return Validator.value(value).required();
    },
    "appDetails.links": (value) => {
      return Validator.value(value).required();
    },
    "appDetails.environments": (value) => {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      isValidAppOwner1:false,
      isValidAppOwner2:false,
      isValidAppOwner3:false,
      hasAdminRights:false,
      appId: null,
      appDetails: null,
      appGroups: null,
      appStages: null,
      appTypes: null,
      appLinks:null,
      appOwners: null,
      businessOwners:null,
      active:0,
      isLoading:true,
      breadcrumb_items: [
        {
          text: "Home",
          to: { name: "apps" },
        },
        {
          text: "Editing App",
          active: true,
        },
      ],
    };
  },
};
</script>
<style scoped>
.floatlabel {
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px !important;
}
.p-col-chip{
background-color: #225083;
color: white;
font-weight: bold;
display:inline !important
}
</style>